import Aos from 'aos'

export class Animation {

    constructor()
    {
        Aos.init({
            offset: 60,
            easing: 'ease-out',
            duration: 1200,
        })
    }
}
