import '@css/main.scss';
import '@js/bootstrap';

import {Header} from '@js/components/Header';
import {Accordion} from '@js/components/Accordion';
import {Animation} from '@js/utils/Animation';

window.onload = () => {

    new Header()
    new Accordion()
    new Animation()

}
